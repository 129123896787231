import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
            selector: 'feedback',
            templateUrl: 'feedback.component.html',
            styleUrls: ['./feedback.component.css'] })
  
export class FeedbackComponent {
    main;
    constructor(main: AppComponent) {
        this.main = main;
    }
}