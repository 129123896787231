<body>
    <br><br><br>
    <div>
        <h1 [class]="main.getHeaderClass()">Cast Videos</h1>
        <div [class]="getContainerClass()">
            <div *ngFor='let item of main.castVideos'>
                <div [class]="getCardClass()">
                    <div>{{item.name.toUpperCase()}}</div>
                    <div>SEASON {{item.season}}</div>
                    <button (click)="loadUrl(item)" class="card-button">WATCH AUDITION</button>
                </div>
            </div>
        </div>
    </div>
</body>


