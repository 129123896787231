import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'landing',
    templateUrl: 'landing.component.html',
    styleUrls: ['./landing.component.css'] })
export class LandingComponent {
    main;
    constructor(main: AppComponent) {
        this.main = main;
    }

    isMobile() {
        if (window.innerWidth < 900) {
            return true;
        }
        return false;
    }

    getHeaderClass1() {
        if (this.isMobile()) {
            return 'h1-mobile';
        }
        return 'h1-desktop';
    }

    getHeaderClass2() {
        if (this.isMobile()) {
            return 'h1-large-mobile';
        }
        return 'h1-large-desktop';
    }
}