import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { AngularFireDatabase } from 'angularfire2/database';
import videosjson from './castvideos.json';

@Component({
  selector: 'cast-videos',
  templateUrl: './cast-videos.component.html',
  styleUrls: ['./cast-videos.component.css']
})
export class CastVideosComponent {
  main;

  constructor(db: AngularFireDatabase, main: AppComponent, private sanitizer: DomSanitizer) {
    this.main = main;
    this.loadCastVideos(db, main);
  }

  loadCastVideos(db, main) {
    // if (main.castVideos === undefined) {
    //   db.list('/cast-videos').valueChanges()
    //   .subscribe(castVideos => {
    //     main.castVideos = castVideos;
    //   })
    // }
    main.castVideos = videosjson["cast-videos"];
  }

  loadUrl(item) {
    window.open("https://www.youtube.com/watch?v=" + item['url'])
  }

  getCardClass() {
    if (window.innerWidth < 750) {
      return "card card-mobile";
    }
    return "card card-desktop";
  }

  getContainerClass() {
    if (window.innerWidth < 750) {
      return "card-container-mobile";
    }
    return "card-container-desktop";
  }
}