import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
            selector: 'about',
            templateUrl: 'about.component.html',
            styleUrls: ['./about.component.css'] })
  
export class AboutComponent {
    main;
    constructor(main: AppComponent) {
        this.main = main;
    }

    getParagraphClass() {
        if (this.main.isMobile()) {
            return 'p-mobile';
        }
        return '';
    }

    getDivClass() {
        if (this.main.isMobile()) {
            return 'div-about-mobile';
        }
        return 'div-about';
    }
}