<br><br><br>
<h1 [class]="main.getHeaderClass()">About SurvivorStudents</h1>
<div [class]="getDivClass()">
    <p [class]="getParagraphClass()">
        <span style="color: #00b56a">SurvivorStudents</span> is a <span style="color: #00b56a">fan-built</span> 
        web app dedicated to providing fans of the cbs television show
        <i>survivor</i> with statistics and resources to aid with the casting process. <br><br>
        We are <span style="color: #00b56a">fans</span> and are <span style="color: #00b56a">not</span> 
        affiliated with cbs or <i>survivor</i> in any way.<br>
        We will <span style="color: #00b56a">not</span> affect the chances of anyone being cast on the show.<br><br>
        To contact us for any reason, email <span style="color: #00b56a">survivorstudents@gmail.com</span>.
    </p>
</div>
