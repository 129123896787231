import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './landing';
import { CastVideosComponent } from './cast-videos';
import { FeedbackComponent } from './feedback';
import { RankingsComponent } from './rankings';
import { AboutComponent } from './about';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'cast-videos', component: CastVideosComponent },
  { path: 'audition-feedback', component: FeedbackComponent },
  { path: 'season-rankings', component: RankingsComponent },
  { path: 'about', component: AboutComponent },

  // otherwise redirect to landing
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
