import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'rankings',
    templateUrl: 'rankings.component.html',
    styleUrls: ['./rankings.component.css'] })
export class RankingsComponent {
    main;
    constructor(main: AppComponent) {
        this.main = main;
    }
}