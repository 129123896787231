export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAWNWDFE_DgXD9avKI5oMlDjL7rRlZdUUk",
    authDomain: "survivorstudents.firebaseapp.com",
    projectId: "survivorstudents",
    storageBucket: "survivorstudents.appspot.com",
    messagingSenderId: "412854075375",
    appId: "1:412854075375:web:7cbb611aab780f76ca001a",
    measurementId: "G-Z845MXH831"
  }
};
