import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  castVideos: any[];
  dropdown: boolean = false;

  constructor(private router: Router, db: AngularFireDatabase) {
  }

  getButtonClass(routeString) {
    if (this.router.url === routeString) {
      return "navbar-button-active";
    }
    else {
      return "navbar-button";
    }
  }

  getImageClass() {
    if (this.router.url === '/') {
      return "navbar-image-active";
    } else {
      return "navbar-image";
    }
  }

  isMobile() {
    if (window.innerWidth < 900) {
      return true;
    }
    return false;
  }

  getFooterClass() {
    if (this.isMobile()) {
      return "footer-mobile";
    }
    return "footer-desktop";
  }

  getHeaderClass() {
    if (this.isMobile()) {
      return 'h1-mobile';
    }
    return '';
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  showDropdown() {
    return this.dropdown;
  }

  getMenuClass() {
    if (this.dropdown === true) {
      return 'navbar-image-menu-active'
    }
    return 'navbar-image-menu';
  }
}


