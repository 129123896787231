
<div class="navbar navbar-desktop" [hidden]="isMobile()">
    <div class="navbar-content">
        <img [class]="getImageClass()" src="assets/home.png" routerLink="/" onclick="this.blur()"/>
        <label class="navbar-title">SurvivorStudents</label>
        <button [class]="getButtonClass('/about')" routerLink="/about">ABOUT</button>
        <button [class]="getButtonClass('/season-rankings')" routerLink="/season-rankings">SEASON RANKINGS</button>
        <button [class]="getButtonClass('/cast-videos')" routerLink="/cast-videos">CAST VIDEOS</button>
        <button [class]="getButtonClass('/audition-feedback')" routerLink="/audition-feedback">AUDITION FEEDBACK</button>
    </div>
</div>
<div class="navbar navbar-mobile" [hidden]="!isMobile()">
    <div class="navbar-content">
        <img [class]="getImageClass()" src="assets/home.png" routerLink="/" onclick="this.blur()"/>
        <label class="navbar-title-mobile">SurvivorStudents</label>
        <img [class]="getMenuClass()" src="assets/menu.png" onclick="this.blur()" (click)="toggleDropdown()"/>
        <div [hidden]="!showDropdown()" id="dropdownMenu" class="dropdown-content">
            <button class="button-dropdown" routerLink="/audition-feedback" (click)="toggleDropdown()">AUDITION FEEDBACK</button>
            <button class="button-dropdown" routerLink="/cast-videos" (click)="toggleDropdown()">CAST VIDEOS</button>
            <button class="button-dropdown" routerLink="/season-rankings" (click)="toggleDropdown()">SEASON RANKINGS</button>
            <button class="button-dropdown" style="border-bottom: 0" routerLink="/about" (click)="toggleDropdown()">ABOUT</button> 
        </div>
    </div>
</div>


<!-- main content container -->
<router-outlet></router-outlet>

<footer [class]="getFooterClass()">QUESTIONS, BUGS, OR SUGGESTIONS? LET US KNOW AT SURVIVORSTUDENTS@GMAIL.COM</footer>

